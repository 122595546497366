@mixin serif() {
    font-family: "Playfair Display", serif;
    font-weight: 700;
    font-style: normal;
}

.serif {
    @include serif();
}

:root {
    --font-family-serif: "Playfair Display", serif;

    --font-family-sans-serif: "Noto Sans", -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
}
