.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 45px;
    color: #fff;

    ul {
        display: flex;
        gap: 16px;

        li {
            list-style: none;

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
