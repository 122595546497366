:root {
    --color-primary-500: #4bd6dc;
    --color-primary-400: #34aabf;
    --color-primary-300: #226a6e;
    --color-primary-200: #0f4b4f;
    --color-primary-100: #111c21;

    --color-neutral-900: #ffffff;
    --color-neutral-800: #f0f3f4;
    --color-neutral-700: #d2dde1;
    --color-neutral-600: #b7c6cb;
    --color-neutral-500: #94a6ae;
    --color-neutral-400: #70858f;
    --color-neutral-300: #516671;
    --color-neutral-200: #374955;
    --color-neutral-100: #1e2a30;
    --color-neutral-0: #000000;
}
