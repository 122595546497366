h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-family-serif);
    color: var(--color-primary-500);
}

p {
    font-family: var(--font-family-sans-serif);
    color: var(--color-neutral-700);
}

a {
    color: var(--color-primary-500);
}
