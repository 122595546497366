.month {
    container-type: inline-size;
    max-width: 1800px;
    margin: 0 auto;
    margin-bottom: 40px;
    padding: 0 24px;

    @media (min-width: 750px) {
        padding: 0 36px;
    }

    h2 {
        color: var(--color-primary-200);
        font-family: var(--font-family-serif);
        font-weight: 700;
        font-size: 100px;
        margin-bottom: 10px;
        letter-spacing: 0.02em;
    }

    .month-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        gap: 20px;
        align-items: center;
        justify-items: center;

        @container (min-width: 1250px) {
            gap: 24px;
            grid-template-columns: repeat(7, 1fr);
        }
    }
}
