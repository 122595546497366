.page-about {
    margin: 0px auto;
    padding-left: 30px;
    padding-right: 60px;
    max-width: 800px;

    @media (min-width: 1000px) {
        margin-top: 100px;
    }

    .logo {
        margin-bottom: 50px;
        width: 175px;

        @media (min-width: 1000px) {
            width: 250px;
        }
    }

    p {
        font-size: 18px;
        line-height: 1.6;
        margin-bottom: 20px;

        @media (min-width: 1000px) {
            font-size: 20px;
        }

        &:last-child {
            color: var(--color-neutral-500);
            margin-top: 50px;
            font-size: 16px;
        }
    }
}
